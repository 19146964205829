import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import Button from '../../components/button/button'
import Badge from '../../components/badge/badge'
import { PostByPathQuery } from '../../../types/graphql-types'
import './style.scss'


const getDescription = (content: string): string => {
  const body = content.replace(
    /<blockquote>/g,
    '<blockquote class="blockquote">'
  )
  if (body.match('<!--more-->')) {
    const [description] = body.split('<!--more-->')
    return description
  }
  return body
}

interface Props {
  data: PostByPathQuery
  options: {
    isIndex: boolean
    adsense?: string | null
  }
}

const Post: React.FC<Props> = ({ data, options }: Props) => {
  const frontmatter = data.post?.frontmatter
  const path = frontmatter?.path || ''
  const { isIndex, adsense } = options
  const html = data.post?.html || ''
  const isMore = isIndex && !!html.match('<!--more-->')

  return (
  <div className="articleBlog">
     <div className="infoBlog">
          <Link style={{ boxShadow: 'none' }} to={path}>
            <span className="title">{frontmatter?.title}</span>
                     </Link>
          <div>
          <Badge label={frontmatter?.category || ''} primary={true} />
          {(frontmatter?.tags || []).map((tag, index) => (
            <Badge label={tag as string} primary={false} key={index} />
          ))}
          </div>
        </div>
        <div
          className="contentBlog"
          dangerouslySetInnerHTML={{
            __html: isMore ? getDescription(html) : html,
          }}
        />
  {isMore}
      </div>

 

)
}

export default Post
